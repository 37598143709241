import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import jesonLebron from "../assets/img/selim-goksin.jpg"
import team1 from "../assets/img/main/trafik-edinme.jpg"
import googleAds from "../assets/img/google-img.png"
import linkedinAds from "../assets/img/linkedin-ads.png"
import fbIns from "../assets/img/fb-instagram.png"
import acquire2 from "../assets/img/teams/edinme-seo.png"
import acquire3 from "../assets/img/teams/edinme-sm.png"
import acquire4 from "../assets/img/teams/edinme-mail.png"
import checkModal from "../assets/img/check-modal.png";



class Convert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }
  handleSubmit(e) {

    e.preventDefault();
    const title = document.title.split('|', 1).toString();
    const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
      method: 'POST',
      body: JSON.stringify({
        "text_327649": title,
        "text_204202": document.querySelector('#site').value,
        "text_775908": document.querySelector('#name').value,
        "text_532192": document.querySelector('#email').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
    response.then(res => {
      if (res.status === 200) {
        document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
      }
      else {
        document.querySelector('.status').innerHTML = "Bir hata oluştu.";
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Trafik Edinme" description="Potansiyel müşterilerinizi websitenize ulaştıracak dijital kampanyaları hayata geçiriyoruz." />
        <div className="acquire">
          <div className="convert-hero container">
            <div className="col-md-4 noPadding">
              <div className="top">
                <h1>Trafik Edinme</h1>
                <p>Potansiyel müşterilerinizi websitenize ulaştıracak dijital kampanyaları hayata geçiriyoruz.</p>
                <p className="content-link" onClick={(e) => this.showModal()}>Ücretsiz marka analizi edinin.</p>
              </div>
              <div className="bottom">
                <h2>eçanta</h2>
                <p>“Flatart’ın ayakları yere sağlam basan stratejileri ve doğru hamleleri sayesinde bize sadece sabretmek ve sonunda hedeflerimizin gerçekleştiğini görmek kaldı.” </p>
                <div className="profile">
                  <div> <img alt="alt text" src={jesonLebron}></img></div>
                  <div><p>Selim Gökşin</p>
                    <p>Co-Founder</p></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 convert-image">
              <img alt="alt text" src={team1} />
              <div className="right-content">
                <div className="col-md-4">
                  <h3>Kaliteli Trafik</h3>
                  <p>Markanızla ilgilenme olasılığı yüksek olan kitleyi markanızla buluşturuyoruz.</p>
                </div>
                <div className="col-md-4">
                  <h3>Size Uygun Mecralar</h3>
                  <p>Hedef kitlenize en etkili yoldan ulaşmanızı sağlayacak trafik kanallarını kullanıyoruz.</p>
                </div>
                <div className="col-md-4">
                  <h3>Şeffaflık</h3>
                  <p>Kaliteli trafik edinmeniz için yürüttüğümüz tüm çalışmaların sonuçlarını sizinle paylaşıyoruz.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="convert-traffic py60">
          <h2>Websitenizin yüksek kaliteli <br />
            trafik elde etmesini sağlayacak aksiyonlar.</h2>
          <p><span>HİZMETLER <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg></span> TRAFİK EDİNME</p>
        </div>

        <div className="acquire-bottom py60">
          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img">
              <img alt="alt text" src={acquire2} />
            </div>
            <div className="acquire-bottom__left-content">
              <h2>Arama Motoru <br />Optimizasyonu (SEO)</h2>
              <p>Markanızın arama motorlarında ilk sıralara çıkmasını sağlayacak aksiyonlar alıyor,  hedef kitlenizin sunduğunuz ürün ve hizmetlere kolayca ulaşmasına zemin hazırlayarak dönüşümü hızlandırıyoruz.</p>
              <p className="small-content">
                - Yüksek dönüşüm oranları<br />
                - Rekabet gücünü artırma<br />
                - Daha iyi kullanıcı deneyimi
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/arama-motoru-optimizasyonu/" className="content-link">SEO sürecine göz atın.</Link></p>
            </div>
          </div>

          <div className="acquire-bottom__right">
            <div className="acquire-bottom__left-content">
              <h2>Sosyal Medya Yönetimi</h2>
              <p>Websitenizdeki trafiği artıracak değer yaratan içerikler oluşturuyor, doğru mecralarda pazarlayarak ilgili hedef kitleye ulaşıyor ve markanız adına fayda sunuyoruz.</p>
              <p className="small-content">
                - Organik trafiği artırma <br />
                - Dijital görünürlük sağlama<br />
                - Müşteri sadakati
              </p>
              <p style={{ marginBottom: 0 }}><Link to="/sosyal-medya/" className="content-link">Sosyal medya sürecine göz atın.</Link></p>
            </div>
            <div className="acquire-bottom__right-img"><img alt="alt text" src={acquire3} /></div>
          </div>

          <div className="acquire-bottom__left">
            <div className="acquire-bottom__left-img"><img alt="alt text" src={acquire4} /></div>
            <div className="acquire-bottom__left-content">
              <h2>E-posta Pazarlama</h2>
              <p>Trafik oranlarını artırmak için düzenli olarak ilgi çekici e-posta bültenleri hazırlıyor, satış döngünüzü hızlandırmanızı sağlıyoruz.</p>
              <p className="small-content">
                - Uygun maliyet<br />
                - Yüksek tıklanma oranları<br />
                - Marka bilinirliğini artırma
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/eposta-pazarlama/" className="content-link">E-posta pazarlama sürecine göz atın.</Link></p>
            </div>
          </div>
          {/* 
          <div className="acquire-bottom__right" >
            <div className="acquire-bottom__left-content" id="icerik-pazarlama">
              <h2>İçerik Pazarlama</h2>
              <p>Websitenizdeki trafiği artıracak değer yaratan içerikler oluşturuyor, doğru mecralarda pazarlayarak ilgili hedef kitleye ulaşıyor ve markanız adına fayda sunuyoruz.</p>
              <small>
                - Organik trafiği artırma <br />
                - Dijital görünürlük sağlama<br />
                - Müşteri sadakati
          </small>
              <p style={{ marginBottom: 0 }}><Link className="content-link">İçerik Pazarlama sürecine göz atın.</Link></p>
            </div>
            <div className="acquire-bottom__right-img"><img alt="alt text" src={acquire2} /></div>
          </div> */}



        </div>

        <div className="services-list__block acquire-contents container">
          <div className="convert-service">
            <div className="col-md-5">
              <h2>Google Ads</h2>
              <p>Google Partner sertifikasına sahip uzman ekibimizle Google Ads reklam kampanyalarınızı ölçeklendirmek ve kârınızı artırmak için çalışıyoruz.</p>
              <p className="small-content">
                - Marka farkındalığını artırma<br />
                - Yeni müşteriler edinme<br />
                - Maliyet kontrolü
             </p>
              <p> <Link to="/digital-ads/" className="content-link">Google Ads sürecine göz atın.</Link></p>
            </div>
            <div className="col-md-5">
              <img alt="alt text" className="acquire-google-img" src={googleAds}></img>
            </div>
          </div>

          <div className="convert-service" id="facebook-reklamlari">
            <div className="col-md-5 fb-inst" style={{ paddingRight: 150 }}>
              <img alt="alt text" src={fbIns}></img>
            </div>
            <div className="col-md-5 fb-inst-c" style={{ paddingLeft: 70 }} >
              <h2>Facebook<br />Instagram Reklamları</h2>
              <p>Doğru kitleye ulaşacak ve yüksek dönüşüm
    oranları sağlayacak Facebook ve Instagram
reklamları kurguluyor, hayata geçiriyor ve sonrasında etkili sonuçlar için reklam çalışmalarını yönetiyoruz.</p>
              <p className="small-content">
                - Marka görünürlüğünü artırma<br />
                - En ilgili kitleyi hedefleme<br />
                - Gerçek zamanlı sonuçlar
        </p>
              {/* <p> <Link className="content-link">Facebook Instagram reklamları sürecine göz atın.</Link></p> */}
            </div>
          </div>

          <div className="convert-service linkedin" id="linkedin-reklamlari">
            <div className="col-md-5">
              <h2>LinkedIn Reklamları</h2>
              <p>İş dünyasının kalbinin attığı bir ortamda markanızın profesyonel hedef kitleyle etkileşime girmesi için dijital reklamlar tasarlıyoruz.</p>
              <p className="small-content">
                - Profesyonel kitleye erişim<br />
                - Yüksek ROI potansiyeli
        </p>
              {/* <p> <Link className="content-link">LinkedIn reklamları sürecine göz atın.</Link></p> */}
            </div>
            <div className="col-md-5 linkedin-img" style={{ padding: '0px 95px 0px 35px' }}>
              <img alt="alt text" src={linkedinAds} ></img>
            </div>
          </div>
          {/* 
          <div className="convert-service py60">
            <div className="col-md-6">
              <img alt="alt text" src={acquire1}></img>
            </div>
            <div className="col-md-5" style={{ paddingLeft: 125 }}>
              <h2>E-posta Pazarlama</h2>
              <p>Trafik oranlarını artırmak için düzenli olarak ilgi çekici e-posta bültenleri hazırlıyor, satış döngünüzü hızlandırmanızı sağlıyoruz.</p>
              <small>
                - Uygun maliyet<br />
                - Yüksek tıklanma oranları<br />
                - Marka bilinirliğini artırma
        </small>
              <p> <Link to="/eposta-pazarlama/" className="content-link">See how we do google ads</Link></p>
            </div>
          </div>


          <div className="convert-service py60">
            <div className="col-md-5">
              <h2>İçerik Pazarlama</h2>
              <p>Websitenizdeki trafiği artıracak değer yaratan içerikler oluşturuyor, doğru mecralarda pazarlayarak ilgili hedef kitleye ulaşıyor ve markanız adına fayda sunuyoruz.</p>
              <small>
                - Organik trafiği artırma <br />
                - Dijital görünürlük sağlama<br />
                - Müşteri sadakati
          </small>
              <p> <Link to="#" className="content-link">See how we do google ads</Link></p>
            </div>
            <div className="col-md-6">
              <img alt="alt text" src={acquire2}></img>
            </div>
          </div>

          <div className="convert-service py60">
            <div className="col-md-6">
              <img alt="alt text" src={acquire3}></img>
            </div>
            <div className="col-md-5" style={{ paddingLeft: 90 }}>
            <h2>Arama Motoru <br />Optimizasyonu (SEO)</h2>
              <p>Markanızın arama motorlarında ilk sıralara çıkmasını sağlayacak aksiyonlar alıyor,  hedef kitlenizin sunduğunuz ürün ve hizmetlere kolayca ulaşmasına zemin hazırlayarak dönüşümü hızlandırıyoruz.</p>
              <small>
                - Yüksek dönüşüm oranları<br />
                - Rekabet gücünü artırma<br />
                - Daha iyi kullanıcı deneyimi
          </small>
              <p> <Link to="/arama-motoru-optimizasyonu/" className="content-link">See how we do google ads</Link></p>
            </div>
          </div> */}
        </div>


        <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Formu doldurun.</h2>             <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Websitenizi girin." />
              <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
              <input type="email" id="email" required  name="text_532192" placeholder="E-posta adresinizi girin." />
              <button>GÖNDER</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </Layout>
    )
  }
}
export default Convert
